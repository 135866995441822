import React, { useState } from 'react';
import emoji from '../images/angry.jpg'

const Luca = () => {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = () => {
        setSubmitted(true);
    };

    return (
        <div style={{ textAlign: 'center', margin: '30px', fontFamily: 'Arial, sans-serif' }}>
            {submitted ? (
                <div>
                <h1 style={{marginTop: 50, fontSize: '3em', color: '#ff0000' }}>Figg di! S git ersch im Februar widr epis!</h1>
                    <img src={emoji} style={{maxWidth: 120}}/></div>
            ) : (
                <>
                    <h1>Sponsor Bestellung - Luca Flück</h1>
                    <p>
                        Im folgenden Feld Name und Sponsor Bestellung vermerken. Wie immer gibt es 40% Rabatt.
                    </p>
                    <form style={{ margin: '20px auto', maxWidth: '400px' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="name" style={{ display: 'block', marginBottom: '5px' }}>
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Name eingeben"
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="order" style={{ display: 'block', marginBottom: '5px' }}>
                                Bestellung
                            </label>
                            <textarea
                                id="order"
                                placeholder="Bestellung eingeben"
                                rows={4}
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            ></textarea>
                        </div>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            style={{
                                padding: '10px 20px',
                                backgroundColor: '#0073e6',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '1em',
                            }}
                        >
                            Bestellung aufgeben
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default Luca;
